import React, { lazy } from "react";
import { Navigate } from "react-router-dom";

const Menu = lazy(() => import("./pages/menu/Menu"));
const Basket = lazy(() => import("./pages/basket/Basket"));
const HomePage = lazy(() => import("./pages/homepage/HomePage"));
const WaiterRequest = lazy(() =>
  import("./pages/waiter-request-view/WaiterRequest")
);
const Account = lazy(() => import("./pages/account-view/Account"));
const OrderConfirm = lazy(() => import("./pages/order-confirm/OrderConfirm"));
const OrderStatus = lazy(() => import("./pages/order-status-view/OrderStatus"));
const Review = lazy(() => import("./pages/review-view/Review"));
const Signin = lazy(() => import("./Auth/Signin"));
const PrivacyPolicy = lazy(() =>
  import("./pages/privacy-policy-view/PrivacyPolicy")
);
const RequestSongFollow = lazy(() =>
  import("./pages/song-follow-view/RequestSongFollow")
);

const publicRoutes = [
  { path: "/", exact: true, element: <HomePage /> },
  { path: "/categories/*", element: <Menu /> },
  { path: "/basket", element: <Basket /> },
  { path: "/privacy_policy", element: <PrivacyPolicy /> },
  { path: "/song_follow", element: <RequestSongFollow /> },
  { path: "/menu_id/:qrcode", element: <Signin /> },
  { path: "/error", element: <Navigate to="/" /> },
];

const privateRoutes = [
  { path: "/orderconfirm", element: <OrderConfirm /> },
  { path: "/waiter_calling", element: <WaiterRequest /> },
  { path: "/order_status", element: <OrderStatus /> },
  { path: "/rating", element: <Review /> },
  { path: "/account", element: <Account /> },
];

export { publicRoutes, privateRoutes };
