import React from "react";
import { useTranslation } from "react-i18next";

export const messageCard = {
  error: (props) => <MessageCard {...props} type="error" />,
  warning: (props) => <MessageCard {...props} type="warning" />,
  info: (props) => <MessageCard {...props} type="info" />,
  success: (props) => <MessageCard {...props} type="success" />,
};

const MessageCard = ({ type, ...props }) => {
  const { t } = useTranslation();

  const messageTypes = {
    error: {
      title: t("66"),
      textColor: "text-red-800",
      bgColor: "bg-red-50",
    },
    warning: {
      title: t("67"),
      textColor: "text-yellow-800",
      bgColor: "bg-yellow-50",
    },
    info: {
      title: t("68"),
      textColor: "text-blue-800",
      bgColor: "bg-blue-50",
    },
    success: {
      title: t("69"),
      textColor: "text-green-800",
      bgColor: "bg-green-50",
    },
  };

  const { title, textColor, bgColor } = messageTypes[type] || {};

  return (
    <div
      className={`p-4 mb-4 text-sm text-center rounded-lg ${bgColor} ${textColor}`}
      role="alert"
    >
      <span className="font-medium">{props.title || title}</span>
    </div>
  );
};

export default MessageCard;
