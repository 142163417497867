import axios from "axios";

const getAuthHeaders = () => ({
  Authorization: `Bearer ${localStorage.getItem("access-token")}`,
});

export const fetchWrapper = {
  get: async (endpoint = "") => {
    const tenantBaseUrl = window._store.getState().tenant.tenantBaseUrl;
    const { data } = await axios.get(`${tenantBaseUrl}/api${endpoint}`);
    return data;
  },

  post: async (endpoint, postData, useAuth = true) => {
    const headers = useAuth ? getAuthHeaders() : {};
    const tenantBaseUrl = window._store.getState().tenant.tenantBaseUrl;
    const { data } = await axios.post(
      `${tenantBaseUrl}/api${endpoint}`,
      postData,
      {
        headers,
      }
    );
    return data;
  },

  put: async (endpoint, postData, useAuth = true) => {
    const headers = useAuth ? getAuthHeaders() : {};
    const tenantBaseUrl = window._store.getState().tenant.tenantBaseUrl;
    const { data } = await axios.post(
      `${tenantBaseUrl}/api${endpoint}`,
      postData,
      {
        headers,
      }
    );
    return data;
  },
};
