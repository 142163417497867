import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { builderCaseFactory } from "../helpers";
import axios from "axios";

const sliceName = "tenant";
const extraActions = createExtraActions(sliceName);
const extraReducers = builderCaseFactory(createExtraReducers());

const initialState = {
  error: null,
  loading: false,
  tenantInfos: [],
  tenantBaseUrl: null,
};

export const tenantSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setTenantBaseUrl: (state, { payload }) => {
      state.tenantBaseUrl = payload;
      localStorage.setItem("tenantBaseUrl", payload);
    },
  },
  extraReducers,
});

function createExtraActions(sliceName) {
  return {
    getTenantInfos: getTenantInfos(),
  };

  function getTenantInfos() {
    return createAsyncThunk(`${sliceName}/GetTenantInfos`, async () => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/GetTenantInfos`
      );

      const tenants = data?.map((t) => ({
        domain: t.domain.split(".")[0],
        url: t.url,
      }));

      return {
        tenants,
      };
    });
  }
}

function createExtraReducers() {
  return {
    ...getTenantInfosReducer(),
  };

  function getTenantInfosReducer() {
    const { pending, fulfilled, rejected } = extraActions.getTenantInfos;
    return {
      [pending]: (state) => {
        state.loading = true;
      },
      [fulfilled]: (state, { payload: { tenants } }) => {
        state.tenantInfos = tenants;
        state.loading = false;
      },
      [rejected]: (state, { error }) => {
        state.loading = false;
        state.error = error.message;
      },
    };
  }
}

export const tenantActions = {
  ...tenantSlice.actions,
  ...extraActions,
};
