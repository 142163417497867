import React from "react";
import { configureStore } from "@reduxjs/toolkit";
import { Provider } from "react-redux";
import { reducer } from "./reducer";
export * from "./actions";

window._store =
  window._store ||
  configureStore({
    reducer,
  });
export const store = window._store;

export const StoreProvider = ({ children }) => (
  <Provider store={store}>{children}</Provider>
);
