import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { builderCaseFactory, fetchWrapper } from "../helpers";

const sliceName = "menu";
const extraActions = createExtraActions(sliceName);
const extraReducers = builderCaseFactory(createExtraReducers());

const initialState = {
  error: null,
  loading: false,
  categories: null,
  products: null,
  showKvkkModal: false,
  showNotificationModal: false,
  selectedCategory: null,
  item: null,
  favoriteProducts: null,
  allProducts: null,
  oldCategoryTitles: {},
};

export const menuSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setShowKvkkModal: (state, { payload }) => {
      state.showKvkkModal = payload;
    },
    setShowNotificationModal: (state, { payload }) => {
      state.showNotificationModal = payload;
    },
    resetProducts: (state) => {
      state.products = null;
    },
  },
  extraReducers,
});

function createExtraActions(sliceName) {
  return {
    getCategoryList: getCategoryList(),
    getProductListById: getProductListById(),
    getCategoryListById: getCategoryListById(),
    getProductDetailById: getProductDetailById(),
    getFavoriteProducts: getFavoriteProducts(),
    getAllProducts: getAllProducts(),
    getCategoryTitlesByIds: getCategoryTitlesByIds(),
  };

  function getCategoryList() {
    return createAsyncThunk(`${sliceName}/getCategoryList`, async () => {
      const response = await fetchWrapper.get(`/category`);

      return response?.categories;
    });
  }

  function getProductListById() {
    return createAsyncThunk(
      `${sliceName}/getProductListById`,
      async (categoryId) => {
        const products = await fetchWrapper.get(
          `/GetProductListById/${categoryId}`
        );

        return {
          products,
          categoryId,
        };
      }
    );
  }
  function getCategoryListById() {
    return createAsyncThunk(
      `${sliceName}/getCategoryListById`,
      async (categoryId) => {
        return await fetchWrapper.get(`/category/${categoryId}`);
      }
    );
  }

  function getProductDetailById() {
    return createAsyncThunk(`${sliceName}/getProductDetailById`, async (id) => {
      const res = await fetchWrapper.post(`/GetProductDetailsById`, { id });

      return res.data;
    });
  }

  function getFavoriteProducts() {
    return createAsyncThunk(`${sliceName}/getFavoriteProducts`, async () => {
      return await fetchWrapper.get(`/favorite_products`);
    });
  }

  function getAllProducts() {
    return createAsyncThunk(`${sliceName}/getAllProducts`, async () => {
      return await fetchWrapper.post(`/GetProducts`);
    });
  }

  function getCategoryTitlesByIds() {
    return createAsyncThunk(
      `${sliceName}/getCategoryTitlesByIds`,
      async (categoryIds) => {
        return await fetchWrapper.post(`/category/titles`, {
          categoryIds,
        });
      }
    );
  }
}

function createExtraReducers() {
  return {
    ...getCategoryListReducer(),
    ...getProductListByIdReducer(),
    ...getCategoryListByIdReducer(),
    ...getProductDetailByIdReducer(),
    ...getFavoriteProductsReducer(),
    ...getAllProductsReducer(),
    ...getCategoryTitlesByIdsReducer(),
  };

  function getCategoryListReducer() {
    const { pending, fulfilled, rejected } = extraActions.getCategoryList;
    return {
      [pending]: (state) => {
        state.loading = true;
      },
      [fulfilled]: (state, { payload }) => {
        state.loading = false;
        state.categories = payload?.sort((a, b) => a.order - b.order);
      },
      [rejected]: (state, { error }) => {
        state.loading = false;
        state.error = error.message;
      },
    };
  }

  function getProductListByIdReducer() {
    const { pending, fulfilled, rejected } = extraActions.getProductListById;
    return {
      [pending]: (state) => {
        state.loading = true;
      },
      [fulfilled]: (state, { payload: { products, categoryId } }) => {
        state.loading = false;
        state.products = products?.sort((a, b) => a.order - b.order);
        if (!state.oldCategoryTitles[categoryId]) {
          let categories = [];
          const selectedCategory = state.selectedCategory;
          if (selectedCategory) {
            if (selectedCategory?.subCategories) {
              const selectedCategoryTitles =
                selectedCategory.subCategories.flatMap((item) => ({
                  id: item.id,
                  title: item.title,
                }));
              categories = [
                ...selectedCategoryTitles,
                { id: selectedCategory.id, title: selectedCategory?.title },
              ];
            } else {
              categories = [selectedCategory.title];
            }
          }

          state.oldCategoryTitles[categoryId] = categories.find(
            (item) => item.id === categoryId
          )?.title;
        }
      },
      [rejected]: (state, { error }) => {
        state.loading = false;
        state.error = error.message;
      },
    };
  }

  function getCategoryListByIdReducer() {
    const { pending, fulfilled, rejected } = extraActions.getCategoryListById;
    return {
      [pending]: (state) => {
        state.loading = true;
      },
      [fulfilled]: (state, { payload }) => {
        state.loading = false;
        state.selectedCategory = payload;
        state.oldCategoryTitles[payload.id] = payload.title;
      },
      [rejected]: (state, { error }) => {
        state.loading = false;
        state.error = error.message;
      },
    };
  }

  function getProductDetailByIdReducer() {
    const { pending, fulfilled, rejected } = extraActions.getProductDetailById;
    return {
      [pending]: (state) => {
        state.loading = true;
      },
      [fulfilled]: (state, { payload }) => {
        state.loading = false;
        state.item = payload;
      },
      [rejected]: (state, { error }) => {
        state.loading = false;
        state.error = error.message;
      },
    };
  }

  function getFavoriteProductsReducer() {
    const { pending, fulfilled, rejected } = extraActions.getFavoriteProducts;
    return {
      [pending]: (state) => {
        state.loading = true;
      },
      [fulfilled]: (state, { payload }) => {
        state.loading = false;
        state.favoriteProducts = payload;
      },
      [rejected]: (state, { error }) => {
        state.loading = false;
        state.error = error.message;
      },
    };
  }

  function getAllProductsReducer() {
    const { pending, fulfilled, rejected } = extraActions.getAllProducts;
    return {
      [pending]: (state) => {
        state.loading = true;
      },
      [fulfilled]: (state, { payload }) => {
        state.loading = false;
        state.allProducts = payload;
      },
      [rejected]: (state, { error }) => {
        state.loading = false;
        state.error = error.message;
      },
    };
  }
  function getCategoryTitlesByIdsReducer() {
    const { pending, fulfilled, rejected } =
      extraActions.getCategoryTitlesByIds;
    return {
      [pending]: (state) => {
        state.loading = true;
      },
      [fulfilled]: (state, { payload }) => {
        state.loading = false;
        Object.keys(payload)?.forEach((key) => {
          state.oldCategoryTitles[key] = payload[key];
        });
      },
      [rejected]: (state, { error }) => {
        state.loading = false;
        state.error = error.message;
      },
    };
  }
}

export const menuActions = {
  ...menuSlice.actions,
  ...extraActions,
};
