export const TFromData = (localizableObject) => {
  const lang = localStorage.getItem("LANG");

  if (lang) {
    switch (lang) {
      case "tr-TR":
        return localizableObject?.TRTR;
      case "en-US":
        if (localizableObject?.ENUS) {
          return localizableObject?.ENUS;
        } else {
          return localizableObject?.TRTR;
        }
      default:
        return localizableObject?.TRTR;
    }
  } else {
    return localizableObject?.TRTR;
  }
};
