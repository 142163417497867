import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { builderCaseFactory, fetchWrapper } from "../helpers";

const sliceName = "order";
const extraActions = createExtraActions(sliceName);
const extraReducers = builderCaseFactory(createExtraReducers());

const initialState = {
  error: null,
  loading: false,
  productsForOrderStatus: null,
  productsForAccount: null,
};

export const orderSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {},
  extraReducers,
});

function createExtraActions(sliceName) {
  return {
    getOrderStatusOfProducts: getOrderStatusOfProducts(),
    getAccountProducts: getAccountProducts(),
    saveOrder: saveOrder(),
  };

  function getOrderStatusOfProducts() {
    return createAsyncThunk(
      `${sliceName}/getOrderStatusOfProducts`,
      async () => {
        return await fetchWrapper.post(`/GetOrderStatusOfProducts`, {
          _token: localStorage.getItem("access-token"),
        });
      }
    );
  }

  function getAccountProducts() {
    return createAsyncThunk(`${sliceName}/getAccountProducts`, async () => {
      return await fetchWrapper.post(`/GetAccountProducts`, {
        _token: localStorage.getItem("access-token"),
      });
    });
  }

  function saveOrder() {
    return createAsyncThunk(`${sliceName}/saveOrder`, async (basket) => {
      return await fetchWrapper.post(`/SaveOrder`, basket);
    });
  }
}

function createExtraReducers() {
  return {
    ...getOrderStatusOfProductsReducer(),
    ...getAccountProductsReducer(),
  };

  function getOrderStatusOfProductsReducer() {
    const { pending, fulfilled, rejected } =
      extraActions.getOrderStatusOfProducts;
    return {
      [pending]: (state) => {
        state.loading = true;
      },
      [fulfilled]: (state, { payload }) => {
        state.loading = false;
        state.productsForOrderStatus = payload;
      },
      [rejected]: (state, { error }) => {
        state.loading = false;
        state.error = error.message;
      },
    };
  }

  function getAccountProductsReducer() {
    const { pending, fulfilled, rejected } = extraActions.getAccountProducts;
    return {
      [pending]: (state) => {
        state.loading = true;
      },
      [fulfilled]: (state, { payload }) => {
        state.loading = false;
        state.productsForAccount = payload.data;
      },
      [rejected]: (state, { error }) => {
        state.loading = false;
        state.error = error.message;
      },
    };
  }
}

export const orderActions = {
  ...orderSlice.actions,
  ...extraActions,
};
