import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { builderCaseFactory, fetchWrapper } from "../helpers";

const sliceName = "auth";
const extraActions = createExtraActions(sliceName);
const extraReducers = builderCaseFactory(createExtraReducers());

const initialState = {
  error: null,
  loading: false,
  user: null,
  loggedIn: false,
};

export const authSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {},
  extraReducers,
});

function createExtraActions(sliceName) {
  return {
    loginUser: loginUser(),
    logoutUser: logoutUser(),
    getUser: getUser(),
  };

  function loginUser() {
    return createAsyncThunk(`${sliceName}/loginUser`, async (body) => {
      return await fetchWrapper.post(`/login`, body);
    });
  }

  function logoutUser() {
    return createAsyncThunk(`${sliceName}/logoutUser`, async () => {
      await fetchWrapper.post(`/logout`, {});
    });
  }

  function getUser() {
    return createAsyncThunk(`${sliceName}/getUser`, async () => {
      return await fetchWrapper.post(`/me`, {
        _token: localStorage.getItem("access-token"),
      });
    });
  }
}

function createExtraReducers() {
  return {
    ...loginUserReducer(),
    ...logoutUserReducer(),
    ...getUserReducer(),
  };

  function loginUserReducer() {
    const { pending, fulfilled, rejected } = extraActions.loginUser;
    return {
      [pending]: (state) => {
        state.loading = true;
      },
      [fulfilled]: (state, { payload }) => {
        state.loggedIn = true;
        state.user = payload.user;
        localStorage.setItem("access-token", payload.access_token);
        state.loading = false;
      },
      [rejected]: (state, { error }) => {
        state.loading = false;
        state.error = error.message;
      },
    };
  }
  function logoutUserReducer() {
    const { pending, fulfilled, rejected } = extraActions.logoutUser;
    return {
      [pending]: (state) => {
        state.loading = true;
      },
      [fulfilled]: (state) => {
        state.loggedIn = false;
        state.user = null;
        localStorage.removeItem("access-token");
        state.loading = false;
      },
      [rejected]: (state, { error }) => {
        state.loading = false;
        state.error = error.message;
      },
    };
  }
  function getUserReducer() {
    const { pending, fulfilled, rejected } = extraActions.getUser;
    return {
      [pending]: (state) => {
        state.loading = true;
      },
      [fulfilled]: (state, { payload }) => {
        state.loggedIn = true;
        state.user = payload;
        state.loading = false;
      },
      [rejected]: (state, { error }) => {
        state.loading = false;
        state.error = error.message;
      },
    };
  }
}

export const authActions = {
  ...authSlice.actions,
  ...extraActions,
};
