import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { builderCaseFactory, fetchWrapper } from "../helpers";

const sliceName = "settings";
const extraActions = createExtraActions(sliceName);
const extraReducers = builderCaseFactory(createExtraReducers());

const initialState = {
  error: null,
  loading: false,
  settings: {
    cancelTime: null,
    orderRequestStatus: null,
    restaurantName: "",
    songRequestStatus: null,
    waiterRequestStatus: null,
    instagramUrl: null,
    mapUrl: "",
    whatsappPhone: null,
    instagramStatus: null,
    whatsappStatus: null,
    googleMapsStatus: null,
    accountRequestStatus: null,
    orderStatus: null,
    reviewStatus: null,
    logo: null,
  },
};

export const settingsSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {},
  extraReducers,
});

function createExtraActions(sliceName) {
  return {
    getSettings: getSettings(),
  };

  function getSettings() {
    return createAsyncThunk(`${sliceName}/getSettings`, async () => {
      const settings = await fetchWrapper.get(`/GetSettings`);

      return {
        cancelTime: settings?.cancelTime,
        orderRequestStatus: settings?.orderRequest,
        restaurantName: settings?.restaurantName || "",
        songRequestStatus: settings?.songRequest,
        waiterRequestStatus: settings?.waiterRequest,
        instagramUrl: settings?.instagramUrl,
        mapUrl: settings?.mapUrl || "",
        whatsappPhone: settings?.whatsappPhone,
        instagramStatus: settings?.instagram,
        whatsappStatus: settings?.whatsapp,
        googleMapsStatus: settings?.googleMaps,
        accountRequestStatus: settings?.accountRequest,
        orderStatus: settings?.orderStatus,
        reviewStatus: settings?.reviewStatus,
        logo: settings?.logo,
      };
    });
  }
}

function createExtraReducers() {
  return {
    ...getSettingsReducer(),
  };

  function getSettingsReducer() {
    const { pending, fulfilled, rejected } = extraActions.getSettings;
    return {
      [pending]: (state) => {
        state.loading = true;
      },
      [fulfilled]: (state, { payload }) => {
        state.loading = false;
        state.settings = payload;
      },
      [rejected]: (state, { error }) => {
        state.loading = false;
        state.error = error.message;
      },
    };
  }
}

export const settingsActions = {
  ...settingsSlice.actions,
  ...extraActions,
};
