import { authSlice } from "./auth.slice";
import { basketSlice } from "./basket.slice";
import { menuSlice } from "./menu.slice";
import { orderSlice } from "./order.slice";
import { requestsSlice } from "./requests.slice";
import { settingsSlice } from "./settings.slice";
import { tenantSlice } from "./tenant.slice";

export const reducer = {
  [authSlice.name]: authSlice.reducer,
  [menuSlice.name]: menuSlice.reducer,
  [tenantSlice.name]: tenantSlice.reducer,
  [settingsSlice.name]: settingsSlice.reducer,
  [requestsSlice.name]: requestsSlice.reducer,
  [basketSlice.name]: basketSlice.reducer,
  [orderSlice.name]: orderSlice.reducer,
};
