import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const NavbarBottom = () => {
  const basket = useSelector((state) => state.basket.basket);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const { waiterRequestStatus, orderRequestStatus } = useSelector(
    (state) => state.settings.settings
  );

  const loggedIn = useSelector((state) => state.auth.loggedIn);

  const getNavItemClassName = (path) => {
    return `flex flex-col items-center justify-center flex-grow w-full min-w-[50px] whitespace-nowrap text-xs ${
      location.pathname === path
        ? "bg-menumate text-white"
        : "text-gray-900 hover:bg-gray-200"
    } transition-colors duration-100`;
  };

  return (
    <nav className="fixed bottom-0 left-0 max-w-[500px] w-full h-14 shadow-xl shadow-black bg-white flex overflow-x-auto">
      <NavItem
        onClick={() => navigate("/")}
        icon="home"
        text={t("13")}
        className={getNavItemClassName("/")}
      />
      <NavItem
        onClick={() => navigate("/categories")}
        icon="dashboard"
        text={t("14")}
        className={getNavItemClassName("/categories")}
      />
      {waiterRequestStatus && loggedIn ? (
        <NavItem
          onClick={() => navigate("/waiter_calling")}
          icon="person"
          text={t("15")}
          className={getNavItemClassName("/waiter_calling")}
        />
      ) : (
        <NavItem
          icon="person"
          text={t("15")}
          disabled
          className={getNavItemClassName("/waiter_calling")}
        />
      )}
      <div className="relative flex flex-col w-full items-center justify-center flex-grow min-w-[50px] whitespace-nowrap text-xs text-gray-900 hover:bg-gray-200 transition-colors duration-100">
        <span
          onClick={() => navigate("/basket")}
          className={getNavItemClassName("/basket")}
        >
          <i className="material-icons text-lg">shopping_basket</i>
          <span className="text-xs">{t("16")}</span>
        </span>
        <Badge count={basket.length} />
      </div>
      {orderRequestStatus && loggedIn ? (
        <NavItem
          onClick={() => navigate("/account")}
          icon="assignment"
          text={t("17")}
          className={getNavItemClassName("/account")}
        />
      ) : (
        <NavItem
          icon="assignment"
          text={t("17")}
          disabled
          className={getNavItemClassName("/account")}
        />
      )}
    </nav>
  );
};

const NavItem = ({ onClick, icon, text, disabled, className }) => {
  return (
    <span
      onClick={disabled ? undefined : onClick}
      className={`flex flex-col items-center justify-center min-w-[50px] whitespace-nowrap text-xs ${
        disabled ? "opacity-40 cursor-not-allowed" : ""
      } ${className}`}
    >
      <i
        className={`material-icons text-lg ${disabled ? "text-gray-500" : ""}`}
      >
        {icon}
      </i>
      <span>{text}</span>
    </span>
  );
};

const Badge = ({ count }) => {
  return (
    <span className="absolute top-3 translate-x-1/2 -translate-y-1/2 bg-menumate text-white text-xs rounded-full px-1.5 py-0.5">
      {count}
    </span>
  );
};

export default NavbarBottom;
