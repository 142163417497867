import React from "react";
import { useTranslation } from "react-i18next";

const ToastCard = ({ type, title, tKey, description, onConfirm }) => {
  const { t } = useTranslation();

  return (
    <div className={`toast-card ${type}`}>
      <div className="toast-header">
        <strong className="mr-auto">{title || t(tKey)}</strong>
      </div>
      {description && <div className="toast-body">{description}</div>}
      {onConfirm && (
        <div className="toast-footer">
          <button onClick={onConfirm}>{t("70")}</button>
        </div>
      )}
    </div>
  );
};

export default ToastCard;
