import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { builderCaseFactory, fetchWrapper, toaster } from "../helpers";

const sliceName = "requests";
const extraActions = createExtraActions(sliceName);
const extraReducers = builderCaseFactory(createExtraReducers());

const initialState = {
  error: null,
  loading: false,
  songRequests: null,
};

export const requestsSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {},
  extraReducers,
});

function createExtraActions(sliceName) {
  return {
    getAllSongs: getAllSongs(),
    removeSongRequestById: removeSongRequestById(),
    saveSongRequestStatus: saveSongRequestStatus(),
    saveWaiterRequest: saveWaiterRequest(),
    saveSongRequest: saveSongRequest(),
    saveReview: saveReview(),
    saveAccountRequest: saveAccountRequest(),
  };

  function getAllSongs() {
    return createAsyncThunk(`${sliceName}/getAllSongs`, async () => {
      return await fetchWrapper.get(`/getAllSongs`);
    });
  }

  function removeSongRequestById() {
    return createAsyncThunk(
      `${sliceName}/removeSongRequestById`,
      async (id) => {
        const res = await fetchWrapper.post(`/update_song_status/${id}`, {
          new_status: 1,
        });

        return {
          res,
          id,
        };
      }
    );
  }

  function saveSongRequestStatus() {
    return createAsyncThunk(
      `${sliceName}/saveSongRequestStatus`,
      async (status) => {
        return await fetchWrapper.post(`/songStatusUpdate`, {
          song_status: status,
        });
      }
    );
  }

  function saveWaiterRequest() {
    return createAsyncThunk(`${sliceName}/saveWaiterRequest`, async (body) => {
      return await fetchWrapper.post(`/waiter`, body);
    });
  }

  function saveSongRequest() {
    return createAsyncThunk(`${sliceName}/saveSongRequest`, async (body) => {
      return await fetchWrapper.post(`/song`, body);
    });
  }

  function saveReview() {
    return createAsyncThunk(`${sliceName}/saveReview`, async (body) => {
      return await fetchWrapper.post(`/review`, body);
    });
  }

  function saveAccountRequest() {
    return createAsyncThunk(`${sliceName}/saveAccountRequest`, async (body) => {
      return await fetchWrapper.post(`/account_request`, body);
    });
  }
}

function createExtraReducers() {
  return {
    ...getAllSongsReducer(),
    ...removeSongRequestByIdReducer(),
    ...saveAccountRequestReducer(),
  };

  function getAllSongsReducer() {
    const { pending, fulfilled, rejected } = extraActions.getAllSongs;
    return {
      [pending]: (state) => {
        state.loading = true;
      },
      [fulfilled]: (state, { payload }) => {
        state.songRequests = payload.data;
        state.loading = false;
      },
      [rejected]: (state, { error }) => {
        state.loading = false;
        state.error = error.message;
      },
    };
  }

  function removeSongRequestByIdReducer() {
    const { pending, fulfilled, rejected } = extraActions.removeSongRequestById;
    return {
      [pending]: (state) => {
        state.loading = true;
      },
      [fulfilled]: (state, { payload }) => {
        const updatedSongs = state.songRequests.map((song) => {
          if (song.id === payload.id) {
            return { ...song, status: 1 };
          }
          return song;
        });
        state.songRequests = updatedSongs.filter((song) => song.status !== 1);
        state.loading = false;
      },
      [rejected]: (state, { error }) => {
        state.loading = false;
        state.error = error.message;
      },
    };
  }

  function saveAccountRequestReducer() {
    const { pending, fulfilled, rejected } = extraActions.saveAccountRequest;
    return {
      [pending]: (state) => {
        state.loading = true;
      },
      [fulfilled]: (state, { payload }) => {
        state.loading = false;
        if (payload?.success) {
          toaster.success({ title: payload?.message });
        }
      },
      [rejected]: (state, { error }) => {
        state.loading = false;
        state.error = error.message;
      },
    };
  }
}

export const requestsActions = {
  ...requestsSlice.actions,
  ...extraActions,
};
