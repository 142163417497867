import React, { Suspense, useEffect } from "react";
import { Route, Routes, Navigate, BrowserRouter } from "react-router-dom";
import Error404 from "./components/error-404/Error404";
import "./i18n";
import { useDispatch, useSelector } from "react-redux";
import { authActions, settingsActions, tenantActions } from "./store";
import Loading from "./components/loading/Loading";
import { privateRoutes, publicRoutes } from "./routes";
import MenumateLogo from "./assets/menumate-logo.png";
import NavbarBottom from "./layout/NavbarBottom";

function App() {
  const dispatch = useDispatch();

  const loggedIn = useSelector((state) => state.auth.loggedIn);
  const loading = useSelector((state) => state.auth.loading);
  const tenantBaseUrl = useSelector((state) => state.tenant.tenantBaseUrl);
  const tenantInfos = useSelector((state) => state.tenant.tenantInfos);

  const url = window.location.href;
  const tenantName = url.split(".")[0].split("//")[1];
  const token = localStorage.getItem("access-token");
  const tenantBaseUrlLS = localStorage.getItem("tenantBaseUrl");

  useEffect(() => {
    if (!tenantBaseUrlLS) {
      dispatch(tenantActions.getTenantInfos());
    } else {
      dispatch(tenantActions.setTenantBaseUrl(tenantBaseUrlLS));
    }
  }, []);

  useEffect(() => {
    if (token && tenantBaseUrl) {
      dispatch(authActions.getUser());
    }
  }, [token, tenantBaseUrl]);

  useEffect(() => {
    if (tenantBaseUrl) {
      dispatch(settingsActions.getSettings());
    }
  }, [tenantBaseUrl]);

  useEffect(() => {
    const tenant = tenantInfos?.find((t) => t.domain === tenantName);
    if (tenantInfos?.length > 0) {
      if (tenant) {
        dispatch(tenantActions.setTenantBaseUrl(tenant.url));
      } else {
        window.location.href = process.env.REACT_APP_NAVIGATE_URL;
      }
    }
  }, [tenantName, tenantInfos]);

  if (loading) {
    return <Loading height="100vh" />;
  }

  return (
    <div className="layout">
      <BrowserRouter>
        <Suspense fallback={<Loading />}>
          <div className="content">
            <Routes>
              {publicRoutes.map(({ path, exact, element }) => (
                <Route key={path} path={path} exact={exact} element={element} />
              ))}
              {loggedIn &&
                privateRoutes.map(({ path, element }) => (
                  <Route key={path} path={path} element={element} />
                ))}
              <Route
                path="*"
                element={loggedIn ? <Error404 /> : <Navigate to="/" />}
              />
            </Routes>
            <NavbarBottom />
          </div>
        </Suspense>
      </BrowserRouter>
      <div className="bigScreenDiv">
        <img src={MenumateLogo} alt="menumate-logo" />
      </div>
    </div>
  );
}

export default App;
